import { getFullUrlPure } from 'hubspot-url-utils/pure';
import { parseNodesFromEmbedHTML } from './injectHTML';
import ScriptExecutionQueue from './ScriptExecutionQueue';
import attachPropToObject from './utils/attachPropToObject';

// Need to disable the rule to have some type-safety here
// eslint-disable-next-line hubspot-dev/no-declarations
// https://stackoverflow.com/a/25346429/27488
function cookieEscape(s) {
  return s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1');
}
function getCookie(name) {
  // eslint-disable-next-line no-var
  var match = document.cookie.match(RegExp(`(?:^|;\\s*)${cookieEscape(name)}=([^;]*)`));
  return match ? match[1] : null;
}
function fetchEmbedHTML(embedDomain, {
  bustCache = false
} = {}) {
  const usp = new URLSearchParams(window.location.search);
  if (bustCache) {
    usp.set('hsCacheBuster', String(Date.now()));
  }

  // Send hubspotutk cookie for smart content
  const hsutk = getCookie('hubspotutk');
  if (hsutk) {
    usp.set('hsutk', hsutk);
  }

  // Set search params for tracking embed usage to map to management UI
  usp.set(`pageUrl`, window.location.href);
  if (document.title) {
    usp.set(`pageTitle`, document.title);
  }
  usp.set('scriptSource', 'Ea'); // Ea = Embed Anywhere

  const searchParams = usp.toString() ? `?${usp.toString()}` : '';
  const embedRenderUrl = `${embedDomain}${searchParams}`;
  return fetch(embedRenderUrl).then(response => {
    if (response.status === 500) throw new Error('Embed HTML Fetch failed');
    return response.text();
  });
}
const getEmbedDomain = embedDetails => {
  const {
    region,
    portalId,
    embedId,
    env = 'prod'
  } = embedDetails;
  const hsSitesUrl = getFullUrlPure(String(portalId), region, env, {
    domainOverride: 'hs-sites',
    hubletPostfixLocation: 'domain'
  });

  // We want to respect the protocol of the hosting site, so just get the host name:
  const hsSitesHostName = new URL(hsSitesUrl).hostname;
  return `//${hsSitesHostName}/_hcms/embed/${embedId}`;
};
function injectHsEmbed(embedDetails, {
  bustCache = false
} = {}) {
  const embedDomain = getEmbedDomain(embedDetails);
  return fetchEmbedHTML(embedDomain, {
    bustCache
  }).then(html => {
    const embedEl = document.getElementById(embedDetails.elementId);
    if (embedEl) {
      embedEl.style.visibility = 'hidden';
      const {
        domNodes,
        linksLoading
      } = parseNodesFromEmbedHTML(html);
      ScriptExecutionQueue.replaceNodesIn(embedEl, domNodes, true);
      linksLoading.then(() => {
        embedEl.style.visibility = 'visible';
      }).catch(err => {
        console.log('Error loading links', err);
      });
    }
  }).catch(err => {
    console.error('Injecting embed HTML errored:', err);
  });
}
attachPropToObject(window, 'hbspt.content.create', injectHsEmbed);